.four0four {
    height: 100vh;
    overflow: hidden;
}

section.content {
    position: relative;
    height: 100vh
}

.lamp {
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    overflow: hidden;
    width: 100vw;
    min-width: 100vw;
    z-index: -1;
    height: 100%;
}

.lava {
    filter: url("#goo");
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.blob {
    border-radius: 50%;
    position: absolute;
    background: #0098c3;
}

.blob.top {
    border-radius: 50%;
    width: 100%;
    height: 14%;
    top: -7%;
    left: 0;
}

.blob.bottom {
    border-radius: 50%;
    width: 110%;
    height: 14%;
    bottom: -7%;
    left: -50px;
}

.blob:nth-child(1) {
    width: 200px;
    height: 200px;
    left: 35%;
    bottom: -15%;

    animation: wobble 4s ease-in-out alternate infinite,
        blob-one ease-in-out 13s infinite;
}

.blob:nth-child(2) {
    width: 230px;
    height: 230px;
    right: 24%;
    bottom: -65%;

    animation: wobble 5s ease-in-out alternate infinite,
        blob-two ease-in-out 22s infinite;
}

.blob:nth-child(3) {
    width: 150px;
    height: 150px;
    bottom: -15%;
    left: 34%;

    animation: wobble 6s ease-in-out alternate infinite,
        blob-three ease-in-out 16s infinite;
}

.blob:nth-child(4) {
    width: 135px;
    height: 135px;
    bottom: -19%;
    left: 30%;

    animation: wobble 7s ease-in-out alternate infinite,
        blob-four ease-in-out 12s infinite;
}

.blob:nth-child(4) {
    width: 35px;
    height: 35px;
    bottom: -19%;
    left: 30%;
    animation: wobble 8s ease-in-out alternate infinite,
        blob-four ease-in-out 12s infinite;
}

.blob:nth-child(5) {
    width: 55px;
    height: 55px;
    bottom: -25%;
    left: 34%;
    animation: wobble 9s ease-in-out alternate infinite,
        blob-five ease-in-out 32s infinite;
}

.blob:nth-child(6) {
    width: 35px;
    height: 35px;
    bottom: -25%;
    right: 34%;
    animation: wobble 10s ease-in-out alternate infinite,
        blob-six ease-in-out 12s infinite;
}

.blob:nth-child(7) {
    width: 235px;
    height: 35px;
    bottom: -85%;
    right: 40%;
    animation: wobble 11s ease-in-out alternate infinite,
        blob-seven ease-in-out 32s infinite;
}

@keyframes blob-one {

    0%,
    100% {
        transform: translatey(0);
    }

    50% {
        transform: translatey(-700%);
    }
}

@keyframes blob-two {

    0%,
    100% {
        transform: translatey(0);
    }

    50% {
        transform: translatey(-420%);
    }
}

@keyframes blob-three {

    0%,
    100% {
        transform: translatey(0);
    }

    50% {
        transform: translatey(-305%);
    }
}

@keyframes blob-four {

    0%,
    100% {
        transform: translatey(0);
    }

    50% {
        transform: translatey(-605%);
    }
}

@keyframes blob-five {

    0%,
    100% {
        transform: translatey(0);
    }

    50% {
        transform: translatey(-700%);
    }
}

@keyframes blob-six {

    0%,
    100% {
        transform: translatey(0);
    }

    50% {
        transform: translatey(-700%);
    }
}

@keyframes blob-seven {

    0%,
    100% {
        transform: translatey(0);
    }

    50% {
        transform: translatey(-300%);
    }
}

@keyframes wobble {
    50% {
        border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    }

    100% {
        border-radius: 38% 52% 75% 36% / 50% 40% 50% 60%;
    }
}






.backGrade {
    background: linear-gradient(-45deg, #E26373, #0098C3, #359F68);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    transition: all .3s ease-in-out;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}