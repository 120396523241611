@import './backgroundGrade.css';
@import './fonts.css';
@import './navbar.css';
@import './stars.css';

:root {
    --blue: #0098c3;
    --bs-body-font-family: 'Roboto' !important;
}

body {}

.colorBlack {
    color: #000;
}

.colorWhite {
    color: #fff;
}

.colorBlue {
    color: var(--blue);
}

.bgBlue {
    background-color: var(--blue);
}

.bgLightGrey {
    background-color: #f7f7f7;
}

.vh25 {
    height: 25vh;
}

.vh50 {
    height: 50vh;
}

.vh75 {
    height: 75vh;
}

.vh100 {
    height: 100vh;
}

.btn-outline-port {
    border: 1px solid #212529 !important;
    --bs-btn-color: #212529;
    --bs-btn-border-color: #212529;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #212529;
    --bs-btn-hover-border-color: #212529;
    --bs-btn-focus-shadow-rgb: 108, 117, 125;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #212529;
    --bs-btn-active-border-color: #212529;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #212529;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #212529;
    --bs-gradient: none;
}

@media(min-width:991px) {
    .cropTR {
        clip-path: polygon(0 0%, 100% 10%, 100% 100%, 0% 100%);
    }

    .cropTL {
        clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0% 100%);
    }

    .cropBR {
        clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
    }

    .cropBL {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 90%);
    }

    .cropR {
        clip-path: polygon(0 0%, 100% 7%, 100% 93%, 0% 100%);
    }

    .cropL {
        clip-path: polygon(0 7%, 100% 0%, 100% 100%, 0% 90%);
    }
}

.py-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
}

.py-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
}

.hidden {
    display: none !important;
}


.card {
    display: flex;
    flex-direction: column;
}

.card-body {
    flex: 1;
    /* Flex to occupy available space */
    display: flex;
    /* Turn card-body into flex container */
    flex-direction: column;
    /* Stack children vertically */
    justify-content: space-between;
    /* Add space between children */
}

.card-button {
    margin-top: auto;
    /* Push button to the bottom */
}